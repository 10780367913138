<template>
    <div>
        <h1 class="text-center display-3 py-md-5">
            Rekisteröi potilaan kieltäy&shy;tyminen
        </h1>

        <v-card>
            <v-card-text>
                <v-form>
                    <header>EHRA-luokka</header>
                    <v-radio-group v-model="selectedReason">
                        <v-radio
                            class="mb-3"
                            v-for="reason in reasons"
                            :key="reason.id"
                            :label="reason.text"
                            :value="reason.id"
                        ></v-radio>
                    </v-radio-group>

                    <v-textarea
                        v-model="rejectReason"
                        label="Syy potilaan kieltäytymiselle"
                        hint="Jos haluat, voit kirjoittaa syyn, että miksi potilas kieltäytyi"
                        auto-grow
                    ></v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="register"
                    :disabled="!selectedReason"
                >
                    Rekisteröi kieltäytyminen
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import ehraReasons from "@/utils/ehra.js";
    export default {
        data() {
            return {
                reasons: ehraReasons,
                selectedReason: "",
                rejectReason: ""
            };
        },

        methods: {
            register() {
                this.$http
                    .post("/api/rejectors", {
                        ehra_class: this.selectedReason,
                        description: this.rejectReason
                    })
                    .then(response => {
                        this.selectedReason = "";
                        this.rejectReason = "";
                        this.$dialog.notify.success("Kieltäytyjä kirjattiin");
                    })
                    .catch(err =>
                        this.$dialog.notify.error(err.response.data.message)
                    );
            }
        }
    };
</script>

<style scoped>
</style>