<template>
    <div>
        <h1 class="text-center display-3 py-md-5">Satunnaista potilas</h1>

        <div class="d-md-flex flex-row">
            <div style="flex: 1; min-width: 50%;">
                <v-alert class="mt-4" type="warning" prominent>
                    Varmista, että potilas on tutkimukseen soveltuva eli
                    hyvävointinen ja turvallisesti kotiutettavissa jos
                    hoitoryhmäksi valikoituu polikliininen hoito
                </v-alert>

                <v-alert type="warning" prominent>
                    Varmista, että potilas on allekirjoittanut suostumuksen
                    tutkimukseen ennen satunnaistamista
                </v-alert>

                <v-alert type="warning" prominent>
                    Varmista, että potilaan syketaajuutta on tarvittaessa
                    hillitty lääkkeellisesti. Tavoitetaso levossa
                    <strong>&lt;110bpm</strong> tai <strong>100bpm</strong> jos
                    potilas on oireinen)
                </v-alert>

                <v-alert v-if="!criteriaOk" type="error" prominent>
                    Tarkista poissulkukriteerit ennen satunnaistamista
                </v-alert>
                <v-alert v-else type="success" prominent>
                    <strong>Poissulkukriteerit tarkastettu!</strong><br />
                    Voit nyt lisätä potilaan
                </v-alert>
            </div>

            <div style="flex: 1; min-width: 50%;" class="pa-md-4">
                <v-card>
                    <v-card-title class="text-uppercase">
                        Poissulkukriteerit
                    </v-card-title>
                    <table>
                        <tbody>
                            <tr v-for="(reason, i) in rejectReasons" :key="i">
                                <td>
                                    <div
                                        class="font-weight-bold py-2 px-6 title"
                                    >
                                        {{ i + 1 }}
                                    </div>
                                </td>
                                <td>{{ reason }}</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td colspan="2">
                                    <v-divider></v-divider>
                                    <v-checkbox
                                        data-test="criteria-read-check"
                                        v-model="criteriaOk"
                                        class="mx-5"
                                        label="Mikään poissulkukriteereistä ei täyty"
                                    >
                                    </v-checkbox>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-card>
            </div>
        </div>

        <div class="text-center pa-8">
            <v-btn
                data-test="add-patient-button"
                x-large
                rounded
                color="success"
                class="px-8"
                @click="randomize"
                :disabled="!criteriaOk"
            >
                <v-icon left large class="mr-5">
                    mdi-dice-multiple
                </v-icon>
                Satunnaista potilas
            </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                criteriaOk: false,
                rejectReasons: [
                    "MAP <60mmHg",
                    "Kardioversio tehtävä muun syyn vuoksi heti",
                    "Muu komplisoiva akuutti sairaustila (esim. dekompensoitu HF tai AMI)",
                    "Antikoagulaatiota ei voida luotettavasti toteuttaa",
                    "Läppäperäinen eteisvärinä (mitraalistenoosi tai mekaaninen läppä)",
                    "Sairaalahoidon tarve",
                    "Poikkeuksellinen tukostaipumus",
                    "TEE-kontraindisoitu"
                ]
            };
        },

        methods: {
            randomize() {
                this.$http
                    .post("/api/patients")
                    .then(response =>
                        this.$router.push({
                            name: "show-patient",
                            params: { id: response.data.id }
                        })
                    )
                    .catch(err =>
                        this.$dialog.notify.error(err.response.data.message)
                    );
            }
        }
    };
</script>

<style scoped>
</style>